<template>
   <div class="ld-panel">
      <span class="ld-frame">
        <img class="ld-img" src="../assets/spinner.svg">
      </span>
    </div>
</template>

<script>
export default {
  name: 'LoadingIcon'
}
</script>

<style>
.ld-panel {
  display: inline;
}
.ld-frame {
    height: 100%;
    vertical-align: middle;
}
.ld-img {
    vertical-align: middle;
    max-height: 30px;
    max-width: 30px;
    margin-top: -5px;
    margin-left: -5px;
}
</style>