import Vue from 'vue'
const ASSETS_URL = 'Assets/'

export default {
  
  getUploaded (userId) {
    return Vue.http.get(ASSETS_URL + 'Uploaded/' + userId)
      .then((response) => { return response.body })
  },

  getSharedDocuments (userId) {
    return Vue.http.get(ASSETS_URL + 'Shared/' + userId)
      .then((response) => { return response.body })
  },
  
  share (fileUpload) {
    return Vue.http.post(ASSETS_URL + 'Shared', fileUpload)
      .then((response) => { return response.body })
  },

  upload (fileUpload) {
    return Vue.http.post(ASSETS_URL + 'Upload', fileUpload)
      .then((response) => { return response.body })
  },

  delete (assetId) {
    return Vue.http.delete(ASSETS_URL + assetId)
      .then((response) => { return response.body })
  },

  download (assetId) {
    return Vue.http({
      method: 'get',
      url: ASSETS_URL + 'Download/' + assetId,
      responseType: 'arraybuffer'
    }).then((response) => { return response })
  },

  downloadAll (assetIds) {
    return Vue.http({
      method: 'get',
      url: ASSETS_URL + 'Download?assetIds=' + assetIds.join('&assetIds='),
      responseType: 'arraybuffer'
    }).then((response) => { return response })
  },

  removeAll (assetIds) {
    return Vue.http.delete(ASSETS_URL + 'RemoveAll?assetIds=' + assetIds.join('&assetIds='))
      .then((response) => { return response.body })
  }
}